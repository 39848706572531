import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Script from 'next/script';

import websiteConfig from '../../../configs/websiteConfig';

function HeadScriptsHtml() {
  const timeoutRef = useRef(0);
  const [loadScripts, setLoadScripts] = useState(false);

  const user = useSelector((state) => state.user);

  const loadScript = useCallback((src, id, onLoad) => {
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      if (onLoad) onLoad();
    };
  }, []);
  const loadScriptHead = useCallback((src, id, onLoad) => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = true;
    head.appendChild(script);
    script.onload = () => {
      if (onLoad) onLoad();
    };
  }, []);

  const loadTawk = useCallback((onLoad) => {
    if (!websiteConfig.tawkId) {
      return;
    }

    // eslint-disable-next-line func-names
    (function () {
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = `https://embed.tawk.to/${websiteConfig.tawkId}/${websiteConfig.tawkType}`;
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
      s1.onload = () => {
        setTimeout(() => {
          if (onLoad) onLoad();
        }, 1000);
      };
    })();
  }, []);

  const onSubscriptionChange = useCallback((isSubscribed) => {
    if (window.OneSignal) {
      if (isSubscribed) {
        window.OneSignal.showNativePrompt();
        if (user) {
          window.OneSignal.setExternalUserId(user.id);
        }
      } else {
        window.OneSignal.removeExternalUserId();
      }
    }
  }, []);

  const onOneSignalLoad = useCallback(() => {
    if (!websiteConfig.oneSignal) {
      return;
    }
    const OneSignal = window.OneSignal || [];

    if (window.OneSignal && !window.OneSignal.initialized) {
      OneSignal.push(() => {
        OneSignal.init({
          appId: websiteConfig.oneSignal,
          notifyButton: {
            enable: true,
          },
        });

        OneSignal.on('subscriptionChange', onSubscriptionChange);
      });
    }
  }, []);

  const onFreshChatLoad = useCallback(() => {
    if (window.FreshworksWidget) {
      window.FreshworksWidget('hide', 'launcher');
    }

    // if (window.fcWidget) {
    //   const data = {};
    //
    //   if (websiteConfig.fcWidgetUuid) {
    //     data.widgetUuid = websiteConfig.fcWidgetUuid;
    //   }
    //
    //   if (websiteConfig.fcHost) {
    //     data.host = websiteConfig.fcHost;
    //   }
    //
    //   if (websiteConfig.fcToken) {
    //     data.token = websiteConfig.fcToken;
    //   }
    //   data.config = {};
    //   data.config.headerProperty = {};
    //   data.config.headerProperty.hideChatButton = true;
    //   window.fcWidget.init(data);
    //   window.fcWidget.hide();
    // }
    if (window?.fcWidget) {
      // console.error('fcWidget', user);
      if (user?.id) {
        window.fcWidget.setExternalId(user.id);
      }

      if (user?.firstName) {
        window.fcWidget?.user?.setFirstName(user.firstName);
      }

      if (user?.lastName) {
        window.fcWidget?.user?.setLastName(user.lastName);
      }

      if (user?.phone) {
        window.fcWidget?.user?.setPhone(user.phone);
      }
    }
  }, []);

  const onFreshChatFcPreFormLoad = useCallback(() => {
    const preChatTemplate = {
      //Form header color and Submit button color.
      mainbgColor: '#000',
      //Form Header Text and Submit button text color.
      maintxColor: '#ffd700',
      //Chat Form Title
      heading: 'Chat with Us',
      //Chat form Welcome Message
      textBanner:
        "We can't wait to talk to you. But first, please take a couple of moments to tell us a bit about yourself.",
      //Submit Button Label.
      SubmitLabel: 'Start Chat',
      //Fields List - Maximum is 5
      //All the values are mandatory and the script will not work if not available.
      fields: {
        field1: {
          //Type for Name - Do not Change
          type: 'name',
          //Label for Field Name, can be in any language
          label: 'Name',
          //Default - Field ID for Name - Do Not Change
          fieldId: 'name',
          //Required "yes" or "no"
          required: 'yes',
          //Error text to be displayed
          error: 'Please Enter a valid name',
        },
        // field2: {
        //   //Type for Email - Do Not Change
        //   type: 'email',
        //   //Label for Field Email, can be in any language
        //   label: 'Email',
        //   //Default - Field ID for Email - Do Not Change
        //   fieldId: 'email',
        //   //Required "yes" or "no"
        //   required: 'yes',
        //   //Error text to be displayed
        //   error: 'Please Enter a valid Email',
        // },
        field2: {
          //Type for Phone - Do Not Change
          type: 'phone',
          //Label for Field Phone, can be in any language
          label: 'Phone',
          //Default - Field ID for Phone - Do Not Change
          fieldId: 'phone',
          //Required "yes" or "no"
          required: 'yes',
          //Error text to be displayed
          error: 'Please Enter a valid Phone Number',
        },
      },
    };
    // window.fcPreChatform.fcWidgetInit(preChatTemplate);
    window.fcSettings = {
      token: websiteConfig.fcToken,
      host: websiteConfig.fcHost,
      widgetUuid: websiteConfig.fcWidgetUuid,
      config: {
        cssNames: {
          //The below element is mandatory. Please add any custom class or leave the default.
          widget: 'custom_fc_frame',
          //The below element is mandatory. Please add any custom class or leave the default.
          expanded: 'custom_fc_expanded',
        },
        headerProperty: {
          hideChatButton: true,
        },
      },
      onInit() {
        console.log('widget init');
        // window.fcPreChatform.fcWidgetInit(preChatTemplate);
      },
    };
    loadScript(
      // 'https://euc-widget.freshworks.com/widgets/103000007565.js',
      'https://pridebetholdingslimited.freshchat.com/js/widget.js',
      'Freshchat-js-sdk',
      onFreshChatLoad
    );
  }, []);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setLoadScripts(true);
    }, 5000);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (loadScripts) {
      // loadScriptHead(
      //   // 'https://euc-widget.freshworks.com/widgets/103000007565.js',
      //   `https://204360.t.hyros.com/v1/lst/universal-script?ph=84b57cce4a92eb97cda2c5448f23c923338fcd2aebc4a694692758afb517bb1b&tag=!clicked&ref_url=${encodeURI(
      //     document.URL
      //   )}`,
      //   'hyros-sdk'
      // );
      if (websiteConfig.fcWidgetId) {
        window.fwSettings = {
          widget_id: websiteConfig.fcWidgetId,
        };

        (function () {
          if (typeof window.FreshworksWidget !== 'function') {
            const n = function () {
              // eslint-disable-next-line prefer-rest-params
              n.q.push(arguments);
            };
            // eslint-disable-next-line no-sequences,no-unused-expressions
            (n.q = []), (window.FreshworksWidget = n);
          }
        })();
        loadScript(
          // 'https://euc-widget.freshworks.com/widgets/103000007565.js',
          'https://snippets.freshchat.com/js/fc-pre-chat-form-v2.min.js',
          'Freshchat-js-sdk-fc-pre-form',
          onFreshChatFcPreFormLoad
        );
        // loadScript(
        //   // 'https://euc-widget.freshworks.com/widgets/103000007565.js',
        //   'https://pridebetholdingslimited.freshchat.com/js/widget.js',
        //   'Freshchat-js-sdk',
        //   onFreshChatLoad
        // );
      }

      if (websiteConfig.oneSignal) {
        loadScript(
          'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
          'one-signal',
          onOneSignalLoad
        );
      }

      if (websiteConfig.tawkId) {
        loadTawk(() => {
          if (
            window?.Tawk_API &&
            typeof window.Tawk_API.hideWidget === 'function'
          ) {
            window.Tawk_API.hideWidget();
          }
          // dispatch(showHideChat(true));
        });
      }
    }
  }, [loadScripts]);

  return (
    <>
      {loadScripts && (
        <>
          {websiteConfig.googleTagId && (
            <Script
              strategy="lazyOnload"
              src={`https://www.googletagmanager.com/gtag/js?id=${websiteConfig.googleTagId}`}
            />
          )}
          {websiteConfig.googleTagManagerDataLayer && (
            <Script
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${websiteConfig.googleTagManagerDataLayer}');`,
              }}
            />
          )}
          {/*        {websiteConfig.googleTagId && (*/}
          {/*          <Script*/}
          {/*            strategy="lazyOnload"*/}
          {/*            dangerouslySetInnerHTML={{*/}
          {/*              __html: `window.dataLayer = window.dataLayer || [];*/}
          {/*function gtag(){dataLayer.push(arguments);}*/}
          {/*gtag('js', new Date());*/}
          {/*window.gtag = gtag;*/}
          {/*gtag('config', '${websiteConfig.googleTagId}');`,*/}
          {/*            }}*/}
          {/*          />*/}
          {/*        )}*/}
          {websiteConfig.googleAnalyticsId && (
            <Script
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

ga('create', '${websiteConfig.googleAnalyticsId}', 'auto');`,
              }}
            />
          )}

          {websiteConfig.facebookTrackingId && (
            <>
              <Script
                strategy="lazyOnload"
                dangerouslySetInnerHTML={{
                  __html: `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${websiteConfig.facebookTrackingId}');
            fbq('track', 'PageView');`,
                }}
              />
              <noscript>
                <img
                  height="1"
                  width="1"
                  style={{ display: 'none' }}
                  alt="facebook"
                  src={`https://www.facebook.com/tr?id=${websiteConfig.facebookTrackingId}&ev=PageView&noscript=1`}
                />
              </noscript>
            </>
          )}
          {websiteConfig.hotjarId && websiteConfig.hotjarSV && (
            <Script
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${websiteConfig.hotjarId},hjsv:${websiteConfig.hotjarSV}};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
              }}
            />
          )}
        </>
      )}
    </>
  );
}

export default React.memo(HeadScriptsHtml);
